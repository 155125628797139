/* index.css */
/* In your index.css or App.css */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300;700&display=swap");

html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2b2b2b; /* Dark grey background */
  overflow-x: hidden; /* Prevent horizontal scrollbars from appearing */
}

#root {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative; /* Ensure the pseudo-elements are positioned correctly */
  background-image: url("../assets/noisy-texture2.png"); /* Use the noise texture image */
  background-size: auto; /* Ensure the image is not scaled */
  background-repeat: repeat; /* Repeat the image to cover the entire background */
  animation: noiseAnimation 1s steps(10) infinite; /* Create the noise animation */
}

@keyframes noiseAnimation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}

/* Your existing CSS */
.headline-yellow {
  font-size: 3.2rem;
  background: repeating-linear-gradient(
    transparent,
    transparent,
    17px,
    #ffea0c,
    #ffea0c,
    17px,
    #ffea0c,
    #ffea0c,
    29px,
    transparent 29px,
    transparent 50px
  );
  background-size: 100% 100%; /* Adjust background size to fit text */
  font-family: "Open Sans Condensed", Tahoma, Verdana, sans-serif;
  font-weight: 700;
  letter-spacing: 0;
  display: inline; /* Makes the background size fit the text content */
}

@media (max-width: 768px) {
  .headline-yellow {
    font-size: 2.5rem;
    margin-bottom: 20px; /* Add margin-bottom for spacing */
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.carousel-container {
  padding-left: 20px;
  padding-right: 20px;
}

.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}
