/* Masonry CSS */
.my-masonry-grid {
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Item margins */
.my-masonry-grid_column > div {
  /* Change div to the appropriate item element */
  margin-bottom: 30px;
}
